@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "IBM Plex Sans",sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "IBM Plex Sans", sans-serif;
}

.card {
    box-shadow: -5px 15px 25px rgba(15, 23, 42, 0.1);

    @media(max-width: 767px){
        max-width: 90vw;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    canvas {
        max-height: 90%;
        object-fit: contain;
        max-width: 90%
    }

    p, li {
        color: theme('colors.slate.200');
        font-size: 18px;
        line-height: 28px;
    }
}

.filters {
    position: sticky;
    top: 30px;
    z-index: 10;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);

    .ais-SearchBox-submit, .ais-SearchBox-reset {
        display: none
    }

    input[type=search],
    select{
        -webkit-appearance: none;
        width: 100%;
        padding: 12px;
        color: rgba(0, 0, 0, 0.76);
        border: 1px rgba(0, 0, 0, 0.24) solid;
        border-radius: 8px;
    }

    .select {
        position: relative;

        select {
            padding-right: 32px;
            &:active, &:focus {
                outline: 0;
            }
        }

        svg {
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);
        }
    }

    .search {
        position: relative;

        input {
            padding-left: 34px;

            &:active, &:focus {
                outline: 0;
            }
        }

        svg {
            position: absolute;
            top: 50%;
            left: 12px;
            transform: translateY(-50%);
        }
    }
}

.card-full {
    overflow: hidden;
    max-height: 0;
    transition: .5s max-height;
    z-index: 9999;
    //position: fixed;
    top: calc((100vh - 720px) / 2);
    left: 50%;
    transform: translateX(-50%);
    width: theme('container.2xl');

    @media(max-width: 1535px){
        width: theme('container.xl');
    }

    @media(max-width: 1280px){
        width: theme('container.lg');
    }

    @media(max-width: 992px){
        width: theme('container.md');
    }

    @media(max-width: 767px){
        width: 90%;
    }

    > div {
        opacity: 0;
        transition: .5s opacity;

        @media(max-width: 1023px){
            overflow: scroll;

            &.grid {
                > div {
                    height: 360px;

                    + div {
                        min-height: 360px;
                        height: fit-content;
                        padding-bottom: 110px;
                    }
                }
            }
        }
    }

    &.active {
        max-height: 720px;

        > div {
            opacity: 1;
            transition-delay: .5s;
        }
    }

    img {
        max-height: 630px;
    }

    ul {
        margin-top: 10px;
        padding-left: 16px;
        list-style: disc;
    }
}

.hero {
    background: #00281D;
    background-size: cover;
    position: relative;
    overflow: hidden;

    .circle {
        position: absolute;

        &.circle-1 {
            z-index: 3;
            width: 33%;
            animation: 30s linear infinite spin;
            bottom: -40%;
            right: -14%;
        }

        &.circle-2 {
            width: 18%;
            z-index: 2;
            animation: 40s linear infinite reverse spin;
            bottom: -42%;
            right: 5%;
        }

        &.circle-3 {
            width: 12%;
            z-index: 2;
            animation: 30s linear infinite reverse spin;
            top: 19%;
            left: 14%;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ais-Hits-item {
    position: relative;
    height: 720px;

    &:nth-of-type(odd) .card-full{
        left: 0;
    }

    &:nth-of-type(even) .card-full{
        left:-706px;
    }

    //&:nth-of-type(3n+3) .card-full{
    //    left:-931px;
    //}
}

.ais-RefinementList-label {
    color: #fff;

    input {
        margin-right: 5px;
    }
}

.select-filter {
    padding: 5px;
    min-width: 50%;
}

.ais-RefinementList-count {
    display: inline-block;
    margin-left: 5px;
    opacity: .5;

    &:before {
        content: '(';
    }

    &:after {
        content: ')';
    }
}

.open-card {
    .canvas-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .canvas-container {
        height: 100% !important;
    }

    canvas {
        height: auto;
        max-height: 90%;
        object-fit: contain;
    }
}

footer {
    background: linear-gradient(180deg, #F8F8F9 0%, rgba(234, 236, 237, 0) 100%), #FFFFFF;

    li {
        font-size: 1.125rem;

        a {
            font-weight: 500;
        }
    }

    hr:after {
        border-bottom: 1px dashed #d5d9dc;
        width: 100%
    }

    hr:after,hr:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    hr:before {
        display: block;
        background: -webkit-gradient(linear,left top,right top,from(#00c693),to(#af45f4));
        background: linear-gradient(90deg,#00c693,#af45f4);
        width: 30%;
        height: 4px;
        border-radius: 2px;
        z-index: 2
    }

    hr {
        margin-top: 3em;
        margin-bottom: 3em;
        width: 100%;
        border: 0;
        height: 1px;
        position: relative;
        background: none;
    }
}