*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #acb2b8;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #5f6a75;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #007eab80;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 2000px) {
  .container {
    max-width: 2000px;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.top-0 {
  top: 0;
}

.top-\[-10rem\] {
  top: -10rem;
}

.left-\[calc\(50\%-11rem\)\] {
  left: calc(50% - 11rem);
}

.top-2 {
  top: .5rem;
}

.left-0 {
  left: 0;
}

.bottom-full {
  bottom: 100%;
}

.-left-\[1px\] {
  left: -1px;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.top-\[-4px\] {
  top: -4px;
}

.bottom-8 {
  bottom: 2rem;
}

.left-8 {
  left: 2rem;
}

.bottom-\[6rem\] {
  bottom: 6rem;
}

.right-8 {
  right: 2rem;
}

.z-20 {
  z-index: 20;
}

.-z-10 {
  z-index: -10;
}

.z-10 {
  z-index: 10;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.-m-1\.5 {
  margin: -.375rem;
}

.-m-1 {
  margin: -.25rem;
}

.m-0 {
  margin: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mr-2 {
  margin-right: .5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.-mt-2 {
  margin-top: -.5rem;
}

.mt-px {
  margin-top: 1px;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-\[21\.1875rem\] {
  height: 21.1875rem;
}

.h-9 {
  height: 2.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-\[24px\] {
  height: 24px;
}

.h-10 {
  height: 2.5rem;
}

.h-full {
  height: 100%;
}

.h-\[350px\] {
  height: 350px;
}

.h-\[250px\] {
  height: 250px;
}

.h-\[100vh\] {
  height: 100vh;
}

.w-full {
  width: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-10 {
  width: 2.5rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-\[240px\] {
  width: 240px;
}

.w-\[100vw\] {
  width: 100vw;
}

.max-w-none {
  max-width: none;
}

.max-w-\[630px\] {
  max-width: 630px;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[30deg\] {
  --tw-rotate: 30deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-9 {
  gap: 2.25rem;
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.overflow-hidden {
  overflow: hidden;
}

.rounded {
  border-radius: 12px;
}

.rounded-lg {
  border-radius: 16px;
}

.rounded-xs {
  border-radius: 3px;
}

.border {
  border-width: 1px;
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(213 217 220 / var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(0 198 147 / var(--tw-border-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 198 147 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 79 59 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 236 237 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(213 217 220 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(19 25 31 / var(--tw-bg-opacity));
}

.p-1\.5 {
  padding: .375rem;
}

.p-1 {
  padding: .25rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-0 {
  padding-top: 0;
}

.pb-\[9rem\] {
  padding-bottom: 9rem;
}

.text-center {
  text-align: center;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-normal {
  font-weight: 400;
}

.font-light {
  font-weight: 300;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-\[1\] {
  line-height: 1;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(0 40 29 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(38 50 62 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(0 198 147 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(19 25 31 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(28 38 47 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(95 106 117 / var(--tw-text-opacity));
}

.text-gray-500, .text-slate-100 {
  --tw-text-opacity: 1;
  color: rgb(47 63 78 / var(--tw-text-opacity));
}

.no-underline {
  text-decoration-line: none;
}

.opacity-50 {
  opacity: .5;
}

.blur-3xl {
  --tw-blur: blur(64px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

body, h1, h2, h3, h4, h5, h6 {
  font-family: IBM Plex Sans, sans-serif;
}

.card {
  box-shadow: -5px 15px 25px #0f172a1a;
}

@media (max-width: 767px) {
  .card {
    max-width: 90vw;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.card canvas {
  max-height: 90%;
  object-fit: contain;
  max-width: 90%;
}

.card p, .card li {
  color: #4d5966;
  font-size: 18px;
  line-height: 28px;
}

.filters {
  z-index: 10;
  position: sticky;
  top: 30px;
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
}

.filters .ais-SearchBox-submit, .filters .ais-SearchBox-reset {
  display: none;
}

.filters input[type="search"], .filters select {
  -webkit-appearance: none;
  width: 100%;
  color: #000000c2;
  border: 1px solid #0000003d;
  border-radius: 8px;
  padding: 12px;
}

.filters .select {
  position: relative;
}

.filters .select select {
  padding-right: 32px;
}

.filters .select select:active, .filters .select select:focus {
  outline: 0;
}

.filters .select svg {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.filters .search {
  position: relative;
}

.filters .search input {
  padding-left: 34px;
}

.filters .search input:active, .filters .search input:focus {
  outline: 0;
}

.filters .search svg {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.card-full {
  max-height: 0;
  z-index: 9999;
  width: 1536px;
  transition: max-height .5s;
  top: calc(50vh - 360px);
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
}

@media (max-width: 1535px) {
  .card-full {
    width: 1280px;
  }
}

@media (max-width: 1280px) {
  .card-full {
    width: 1024px;
  }
}

@media (max-width: 992px) {
  .card-full {
    width: 768px;
  }
}

@media (max-width: 767px) {
  .card-full {
    width: 90%;
  }
}

.card-full > div {
  opacity: 0;
  transition: opacity .5s;
}

@media (max-width: 1023px) {
  .card-full > div {
    overflow: scroll;
  }

  .card-full > div.grid > div {
    height: 360px;
  }

  .card-full > div.grid > div + div {
    min-height: 360px;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 110px;
  }
}

.card-full.active {
  max-height: 720px;
}

.card-full.active > div {
  opacity: 1;
  transition-delay: .5s;
}

.card-full img {
  max-height: 630px;
}

.card-full ul {
  margin-top: 10px;
  padding-left: 16px;
  list-style: disc;
}

.hero {
  background: #00281d 0 0 / cover;
  position: relative;
  overflow: hidden;
}

.hero .circle {
  position: absolute;
}

.hero .circle.circle-1 {
  z-index: 3;
  width: 33%;
  animation: 30s linear infinite spin;
  bottom: -40%;
  right: -14%;
}

.hero .circle.circle-2 {
  width: 18%;
  z-index: 2;
  animation: 40s linear infinite reverse spin;
  bottom: -42%;
  right: 5%;
}

.hero .circle.circle-3 {
  width: 12%;
  z-index: 2;
  animation: 30s linear infinite reverse spin;
  top: 19%;
  left: 14%;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ais-Hits-item {
  height: 720px;
  position: relative;
}

.ais-Hits-item:nth-of-type(2n+1) .card-full {
  left: 0;
}

.ais-Hits-item:nth-of-type(2n) .card-full {
  left: -706px;
}

.ais-RefinementList-label {
  color: #fff;
}

.ais-RefinementList-label input {
  margin-right: 5px;
}

.select-filter {
  min-width: 50%;
  padding: 5px;
}

.ais-RefinementList-count {
  opacity: .5;
  margin-left: 5px;
  display: inline-block;
}

.ais-RefinementList-count:before {
  content: "(";
}

.ais-RefinementList-count:after {
  content: ")";
}

.open-card .canvas-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100% !important;
}

.open-card canvas {
  height: auto;
  max-height: 90%;
  object-fit: contain;
}

footer {
  background: linear-gradient(#f8f8f9 0%, #eaeced00 100%), #fff;
}

footer li {
  font-size: 1.125rem;
}

footer li a {
  font-weight: 500;
}

footer hr:after {
  width: 100%;
  border-bottom: 1px dashed #d5d9dc;
}

footer hr:after, footer hr:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

footer hr:before {
  width: 30%;
  height: 4px;
  z-index: 2;
  background: linear-gradient(90deg, #00c693, #af45f4);
  border-radius: 2px;
  display: block;
}

footer hr {
  width: 100%;
  height: 1px;
  background: none;
  border: 0;
  margin-top: 3em;
  margin-bottom: 3em;
  position: relative;
}

.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(130 140 149 / var(--tw-border-opacity));
}

.hover\:border-green-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(102 221 190 / var(--tw-border-opacity));
}

.hover\:bg-green-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(102 221 190 / var(--tw-bg-opacity));
}

.hover\:text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgb(130 140 149 / var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(38 50 62 / var(--tw-text-opacity));
}

.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgb(19 25 31 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:relative {
    position: relative;
  }

  .sm\:top-\[-20rem\] {
    top: -20rem;
  }

  .sm\:left-\[calc\(50\%-30rem\)\] {
    left: calc(50% - 30rem);
  }

  .sm\:h-\[42\.375rem\] {
    height: 42.375rem;
  }
}

@media (min-width: 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:m-0 {
    margin: 0;
  }

  .md\:mt-5 {
    margin-top: 1.25rem;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:w-8\/12 {
    width: 66.6667%;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:pb-20 {
    padding-bottom: 5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:absolute {
    position: absolute;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:w-7\/12 {
    width: 58.3333%;
  }

  .lg\:min-w-0 {
    min-width: 0;
  }

  .lg\:flex-1 {
    flex: 1;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:pt-28 {
    padding-top: 7rem;
  }

  .lg\:pb-28 {
    padding-bottom: 7rem;
  }

  .lg\:text-\[70px\] {
    font-size: 70px;
  }

  .lg\:text-\[20px\] {
    font-size: 20px;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1280px) {
  .xl\:mb-12 {
    margin-bottom: 3rem;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:w-6\/12 {
    width: 50%;
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:justify-end {
    justify-content: flex-end;
  }

  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:pt-24 {
    padding-top: 6rem;
  }

  .xl\:text-\[80px\] {
    font-size: 80px;
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .\32 xl\:text-\[100px\] {
    font-size: 100px;
  }
}

/*# sourceMappingURL=index.65ba252d.css.map */
